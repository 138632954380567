import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {Observable, EMPTY, of} from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { MsalService } from './msal.service';
import {
  AccountInfo,
  AuthenticationResult,
  AuthorizationUrlRequest,
  BrowserConfigurationAuthError,
  InteractionType,
} from '@azure/msal-browser';
import { Injectable, Inject } from '@angular/core';
import {MSAL_INTERCEPTOR_CONFIG, SESSION_INSTANCE} from './constants';
import {MsalInterceptorAuthRequest, MsalInterceptorConfig} from './msal.interceptor.config';
import {SessionService} from '../shared/shared-services/session/session.service';

@Injectable()
export class MsalInterceptor implements HttpInterceptor {
  constructor(
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfig,
    @Inject(SESSION_INSTANCE) private sessionService: SessionService, // keep here to force injection of listener before HTTP calls start
    private authService: MsalService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.msalInterceptorConfig.interactionType !== InteractionType.Popup
      && this.msalInterceptorConfig.interactionType !== InteractionType.Redirect) {
      throw new BrowserConfigurationAuthError('invalid_interaction_type',
        'Invalid interaction type provided to MSAL Interceptor. InteractionType.Popup, ' +
        'InteractionType.Redirect must be provided in the msalInterceptorConfiguration');
    }

    this.authService.getLogger().verbose('MSAL Interceptor activated');
    const scopes = this.msalInterceptorConfig.scopes;

    // If no scopes for endpoint, does not acquire token
    if (!scopes || scopes.length === 0) {
      this.authService.getLogger().verbose('Interceptor - no scopes for endpoint');
      return next.handle(req);
    }

    // Sets account as active account or first account
    let account: AccountInfo;
    if (!!this.authService.getActiveAccount()) {
      this.authService.getLogger().verbose('Interceptor - active account selected');
      account = this.authService.getActiveAccount();
    } else {
      this.authService.getLogger().verbose('Interceptor - no active account, fallback to first account');
      account = this.authService.getAllAccounts()[0];
    }

    const authRequest = typeof this.msalInterceptorConfig.authRequest === 'function'
      ? this.msalInterceptorConfig.authRequest(this.authService, req, { account })
      : { ...this.msalInterceptorConfig.authRequest, account };

    this.authService.getLogger().info(`Interceptor - ${scopes.length} scopes found for endpoint`);
    this.authService.getLogger().infoPii(`Interceptor - [${scopes}] scopes found for ${req.url}`);

    // Note: For MSA accounts, include openid scope when calling acquireTokenSilent to return idToken
    return this.authService.acquireTokenSilent({...authRequest, scopes, account })
      .pipe(
        catchError(() => {
          this.authService.getLogger().error('Interceptor - acquireTokenSilent rejected with error. Invoking interaction to resolve.');
          return this.acquireTokenInteractively(authRequest, scopes);
        }),
        switchMap((result: AuthenticationResult)  => {
          if (!result.accessToken) {
            this.authService.getLogger().error('Interceptor - acquireTokenSilent resolved with null access token. Known issue with B2C tenants, invoking interaction to resolve.');
            return this.acquireTokenInteractively(authRequest, scopes);
          }
          return of(result);
        }),
        switchMap((result: AuthenticationResult) => {
          this.authService.getLogger().verbose('Interceptor - setting authorization headers');
          const headers = req.headers
            .set('Authorization', `Bearer ${result.accessToken}`);

          const requestClone = req.clone({headers});
          return next.handle(requestClone);
        })
      );
  }

  /**
   * Invoke interaction for the given set of scopes
   * @param scopes Array of scopes for the request
   * @returns Result from the interactive request
   */
  private acquireTokenInteractively(authRequest: MsalInterceptorAuthRequest, scopes: string[]): Observable<AuthenticationResult> {
    if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
      this.authService.getLogger().verbose('Interceptor - error acquiring token silently, acquiring by popup');
      return this.authService.acquireTokenPopup({ ...authRequest, scopes } as AuthorizationUrlRequest);
    }
    return EMPTY;
  }

}
