import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AccountInfo, AuthenticationResult, EventMessage, EventType} from '@azure/msal-browser';
import {MsalBroadcastService} from '../../../msal/msal.broadcast.service';
import {filter} from 'rxjs/operators';
import {MsalService} from '../../../msal/msal.service';
import {Router} from '@angular/router';
import {FeatureFlagService} from '../feature-flag/feature-flag.service';

export interface SessionInfo {
  userTypeCode: string;
  sessionId: string;
  issuedAt: number;
  expireAt: number;
  familyName?: string;
  givenName?: string;
  userId?: string;
}

export enum AuthErrorMode {
  RedirectToLogin,
  ShowErrorAndLogout,
}

@Injectable()
export class SessionService {

  private subSessionInfo: BehaviorSubject<SessionInfo>;
  public sessionInfo: Observable<SessionInfo>;
  public currentSession: SessionInfo;

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router,
    private featureFlagService: FeatureFlagService,
  ) {
    this.subSessionInfo = new BehaviorSubject<SessionInfo>(null);
    this.sessionInfo = this.subSessionInfo.asObservable();
    // catch msal events and initialize session
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.setActiveAccount(payload.account);
        this.initSession(payload.account);
      });
  }

  public initSession(authResult: AccountInfo): void {
    if (authResult.idTokenClaims != null) {
      const sessionInfo = {
        // @ts-ignore
        sessionId: authResult.idTokenClaims.sid,
        // @ts-ignore
        issuedAt: authResult.idTokenClaims.iat,
        // @ts-ignore
        expireAt: authResult.idTokenClaims.exp,
        // @ts-ignore
        userId: String(authResult.idTokenClaims.uid),
        // @ts-ignore
        roles: Array.isArray(authResult.idTokenClaims.roles) ? authResult.idTokenClaims.roles : authResult.idTokenClaims.roles.split(','),
        // @ts-ignore
        email: authResult.idTokenClaims.email as string,
        // @ts-ignore
        userTypeCode: String(authResult.idTokenClaims.utc),
        // @ts-ignore
        familyName: String(authResult.idTokenClaims.family_name),
        // @ts-ignore
        givenName: String(authResult.idTokenClaims.given_name),
      };
      // init session is firing every time token is acquired (which is every REST call),
      // make sure we publish only unique session change events (either new session or token is refreshed)
      if (!this.currentSession || this.currentSession.sessionId !== sessionInfo.sessionId ||
        this.currentSession.issuedAt !== sessionInfo.issuedAt || this.currentSession.expireAt !== sessionInfo.expireAt) {
        this.currentSession = sessionInfo;
        this.subSessionInfo.next(this.currentSession);
        this.featureFlagService.setSession(sessionInfo.userId, sessionInfo.familyName, sessionInfo.givenName,
          sessionInfo.email, sessionInfo.userTypeCode, sessionInfo.roles);
      }
    } else {
      this.currentSession = null;
      this.subSessionInfo.next(null);
      this.featureFlagService.resetSession();
    }
  }

  isRouteAuthRequired(): boolean {
    return this.router.url.indexOf('/auth-error') === -1;
  }

  getShowAuthErrorMode(errorID: string): AuthErrorMode {
    if (errorID === 'AADB2C90091') {
      return AuthErrorMode.RedirectToLogin;
    }
    return AuthErrorMode.ShowErrorAndLogout;
  }

}
