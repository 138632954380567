{
  "main": {
    "es": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "es"
      },
      "dates": {
        "fields": {
          "era": {
            "displayName": "era"
          },
          "era-short": {
            "displayName": "era"
          },
          "era-narrow": {
            "displayName": "era"
          },
          "year": {
            "displayName": "año",
            "relative-type--1": "el año pasado",
            "relative-type-0": "este año",
            "relative-type-1": "el próximo año",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} año",
              "relativeTimePattern-count-other": "dentro de {0} años"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} año",
              "relativeTimePattern-count-other": "hace {0} años"
            }
          },
          "year-short": {
            "displayName": "a",
            "relative-type--1": "el año pasado",
            "relative-type-0": "este año",
            "relative-type-1": "el próximo año",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} a",
              "relativeTimePattern-count-other": "dentro de {0} a"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} a",
              "relativeTimePattern-count-other": "hace {0} a"
            }
          },
          "year-narrow": {
            "displayName": "a",
            "relative-type--1": "el año pasado",
            "relative-type-0": "este año",
            "relative-type-1": "el próximo año",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} a",
              "relativeTimePattern-count-other": "dentro de {0} a"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} a",
              "relativeTimePattern-count-other": "hace {0} a"
            }
          },
          "quarter": {
            "displayName": "trimestre",
            "relative-type--1": "el trimestre pasado",
            "relative-type-0": "este trimestre",
            "relative-type-1": "el próximo trimestre",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} trimestre",
              "relativeTimePattern-count-other": "dentro de {0} trimestres"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} trimestre",
              "relativeTimePattern-count-other": "hace {0} trimestres"
            }
          },
          "quarter-short": {
            "displayName": "trim.",
            "relative-type--1": "el trimestre pasado",
            "relative-type-0": "este trimestre",
            "relative-type-1": "el próximo trimestre",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} trim.",
              "relativeTimePattern-count-other": "dentro de {0} trim."
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} trim.",
              "relativeTimePattern-count-other": "hace {0} trim."
            }
          },
          "quarter-narrow": {
            "displayName": "trim.",
            "relative-type--1": "el trimestre pasado",
            "relative-type-0": "este trimestre",
            "relative-type-1": "el próximo trimestre",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} trim.",
              "relativeTimePattern-count-other": "dentro de {0} trim."
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} trim.",
              "relativeTimePattern-count-other": "hace {0} trim."
            }
          },
          "month": {
            "displayName": "mes",
            "relative-type--1": "el mes pasado",
            "relative-type-0": "este mes",
            "relative-type-1": "el próximo mes",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} mes",
              "relativeTimePattern-count-other": "dentro de {0} meses"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} mes",
              "relativeTimePattern-count-other": "hace {0} meses"
            }
          },
          "month-short": {
            "displayName": "m",
            "relative-type--1": "el mes pasado",
            "relative-type-0": "este mes",
            "relative-type-1": "el próximo mes",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} m",
              "relativeTimePattern-count-other": "dentro de {0} m"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} m",
              "relativeTimePattern-count-other": "hace {0} m"
            }
          },
          "month-narrow": {
            "displayName": "m",
            "relative-type--1": "el mes pasado",
            "relative-type-0": "este mes",
            "relative-type-1": "el próximo mes",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} m",
              "relativeTimePattern-count-other": "dentro de {0} m"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} m",
              "relativeTimePattern-count-other": "hace {0} m"
            }
          },
          "week": {
            "displayName": "semana",
            "relative-type--1": "la semana pasada",
            "relative-type-0": "esta semana",
            "relative-type-1": "la próxima semana",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} semana",
              "relativeTimePattern-count-other": "dentro de {0} semanas"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} semana",
              "relativeTimePattern-count-other": "hace {0} semanas"
            },
            "relativePeriod": "la semana del {0}"
          },
          "week-short": {
            "displayName": "sem.",
            "relative-type--1": "sem. ant.",
            "relative-type-0": "esta sem.",
            "relative-type-1": "próx. sem.",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} sem.",
              "relativeTimePattern-count-other": "dentro de {0} sem."
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} sem.",
              "relativeTimePattern-count-other": "hace {0} sem."
            },
            "relativePeriod": "la sem. del {0}"
          },
          "week-narrow": {
            "displayName": "sem.",
            "relative-type--1": "sem. ant.",
            "relative-type-0": "esta sem.",
            "relative-type-1": "próx. sem.",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} sem.",
              "relativeTimePattern-count-other": "dentro de {0} sem."
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} sem.",
              "relativeTimePattern-count-other": "hace {0} sem."
            },
            "relativePeriod": "la sem. del {0}"
          },
          "weekOfMonth": {
            "displayName": "semana del mes"
          },
          "weekOfMonth-short": {
            "displayName": "sem. de mes"
          },
          "weekOfMonth-narrow": {
            "displayName": "sem. de mes"
          },
          "day": {
            "displayName": "día",
            "relative-type--2": "anteayer",
            "relative-type--1": "ayer",
            "relative-type-0": "hoy",
            "relative-type-1": "mañana",
            "relative-type-2": "pasado mañana",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} día",
              "relativeTimePattern-count-other": "dentro de {0} días"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} día",
              "relativeTimePattern-count-other": "hace {0} días"
            }
          },
          "day-short": {
            "displayName": "d",
            "relative-type--2": "anteayer",
            "relative-type--1": "ayer",
            "relative-type-0": "hoy",
            "relative-type-1": "mañana",
            "relative-type-2": "pasado mañana",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} día",
              "relativeTimePattern-count-other": "dentro de {0} días"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} día",
              "relativeTimePattern-count-other": "hace {0} días"
            }
          },
          "day-narrow": {
            "displayName": "d",
            "relative-type--2": "anteayer",
            "relative-type--1": "ayer",
            "relative-type-0": "hoy",
            "relative-type-1": "mañana",
            "relative-type-2": "pasado mañana",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} día",
              "relativeTimePattern-count-other": "dentro de {0} días"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} día",
              "relativeTimePattern-count-other": "hace {0} días"
            }
          },
          "dayOfYear": {
            "displayName": "día del año"
          },
          "dayOfYear-short": {
            "displayName": "día del a"
          },
          "dayOfYear-narrow": {
            "displayName": "día del a"
          },
          "weekday": {
            "displayName": "día de la semana"
          },
          "weekday-short": {
            "displayName": "día de sem."
          },
          "weekday-narrow": {
            "displayName": "día de sem."
          },
          "weekdayOfMonth": {
            "displayName": "día de la semana del mes"
          },
          "weekdayOfMonth-short": {
            "displayName": "día de sem. de mes"
          },
          "weekdayOfMonth-narrow": {
            "displayName": "día de sem. de mes"
          },
          "sun": {
            "relative-type--1": "el domingo pasado",
            "relative-type-0": "este domingo",
            "relative-type-1": "el próximo domingo",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} domingo",
              "relativeTimePattern-count-other": "dentro de {0} domingos"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} domingo",
              "relativeTimePattern-count-other": "hace {0} domingos"
            }
          },
          "sun-short": {
            "relative-type--1": "el dom. pasado",
            "relative-type-0": "este dom.",
            "relative-type-1": "el próximo dom.",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} dom.",
              "relativeTimePattern-count-other": "dentro de {0} dom."
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} dom.",
              "relativeTimePattern-count-other": "hace {0} dom."
            }
          },
          "sun-narrow": {
            "relative-type--1": "el DO pasado",
            "relative-type-0": "este DO",
            "relative-type-1": "el próximo DO",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} DO",
              "relativeTimePattern-count-other": "dentro de {0} DO"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} DO",
              "relativeTimePattern-count-other": "hace {0} DO"
            }
          },
          "mon": {
            "relative-type--1": "el lunes pasado",
            "relative-type-0": "este lunes",
            "relative-type-1": "el próximo lunes",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} lunes",
              "relativeTimePattern-count-other": "dentro de {0} lunes"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} lunes",
              "relativeTimePattern-count-other": "hace {0} lunes"
            }
          },
          "mon-short": {
            "relative-type--1": "el lun. pasado",
            "relative-type-0": "este lun.",
            "relative-type-1": "el próximo lun.",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} lun.",
              "relativeTimePattern-count-other": "dentro de {0} lun."
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} lun.",
              "relativeTimePattern-count-other": "hace {0} lun."
            }
          },
          "mon-narrow": {
            "relative-type--1": "el LU pasado",
            "relative-type-0": "este LU",
            "relative-type-1": "el próximo LU",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} LU",
              "relativeTimePattern-count-other": "dentro de {0} LU"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} LU",
              "relativeTimePattern-count-other": "hace {0} LU"
            }
          },
          "tue": {
            "relative-type--1": "el martes pasado",
            "relative-type-0": "este martes",
            "relative-type-1": "el próximo martes",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} martes",
              "relativeTimePattern-count-other": "dentro de {0} martes"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} martes",
              "relativeTimePattern-count-other": "hace {0} martes"
            }
          },
          "tue-short": {
            "relative-type--1": "el mar. pasado",
            "relative-type-0": "este mar.",
            "relative-type-1": "el próximo mar.",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} mar.",
              "relativeTimePattern-count-other": "dentro de {0} mar."
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} mar.",
              "relativeTimePattern-count-other": "hace {0} mar."
            }
          },
          "tue-narrow": {
            "relative-type--1": "el MA pasado",
            "relative-type-0": "este MA",
            "relative-type-1": "el próximo MA",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} MA",
              "relativeTimePattern-count-other": "dentro de {0} MA"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} MA",
              "relativeTimePattern-count-other": "hace {0} MA"
            }
          },
          "wed": {
            "relative-type--1": "el miércoles pasado",
            "relative-type-0": "este miércoles",
            "relative-type-1": "el próximo miércoles",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} miércoles",
              "relativeTimePattern-count-other": "dentro de {0} miércoles"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} miércoles",
              "relativeTimePattern-count-other": "hace {0} miércoles"
            }
          },
          "wed-short": {
            "relative-type--1": "el mié. pasado",
            "relative-type-0": "este mié.",
            "relative-type-1": "el próximo mié.",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} mié.",
              "relativeTimePattern-count-other": "dentro de {0} mié."
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} mié.",
              "relativeTimePattern-count-other": "hace {0} mié."
            }
          },
          "wed-narrow": {
            "relative-type--1": "el MI pasado",
            "relative-type-0": "este MI",
            "relative-type-1": "el próximo MI",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} MI",
              "relativeTimePattern-count-other": "dentro de {0} MI"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} MI",
              "relativeTimePattern-count-other": "hace {0} MI"
            }
          },
          "thu": {
            "relative-type--1": "el jueves pasado",
            "relative-type-0": "este jueves",
            "relative-type-1": "el próximo jueves",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} jueves",
              "relativeTimePattern-count-other": "dentro de {0} jueves"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} jueves",
              "relativeTimePattern-count-other": "hace {0} jueves"
            }
          },
          "thu-short": {
            "relative-type--1": "el jue. pasado",
            "relative-type-0": "este jue.",
            "relative-type-1": "el próximo jue.",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} jue.",
              "relativeTimePattern-count-other": "dentro de {0} jue."
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} jue.",
              "relativeTimePattern-count-other": "hace {0} jue."
            }
          },
          "thu-narrow": {
            "relative-type--1": "el JU pasado",
            "relative-type-0": "este JU",
            "relative-type-1": "el próximo JU",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} JU",
              "relativeTimePattern-count-other": "dentro de {0} JU"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} JU",
              "relativeTimePattern-count-other": "hace {0} JU"
            }
          },
          "fri": {
            "relative-type--1": "el viernes pasado",
            "relative-type-0": "este viernes",
            "relative-type-1": "el próximo viernes",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} viernes",
              "relativeTimePattern-count-other": "dentro de {0} viernes"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} viernes",
              "relativeTimePattern-count-other": "hace {0} viernes"
            }
          },
          "fri-short": {
            "relative-type--1": "el vie. pasado",
            "relative-type-0": "este vie.",
            "relative-type-1": "el próximo vie.",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} vie.",
              "relativeTimePattern-count-other": "dentro de {0} vie."
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} vie.",
              "relativeTimePattern-count-other": "hace {0} vie."
            }
          },
          "fri-narrow": {
            "relative-type--1": "el VI pasado",
            "relative-type-0": "este VI",
            "relative-type-1": "el próximo VI",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} VI",
              "relativeTimePattern-count-other": "dentro de {0} VI"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} VI",
              "relativeTimePattern-count-other": "hace {0} VI"
            }
          },
          "sat": {
            "relative-type--1": "el sábado pasado",
            "relative-type-0": "este sábado",
            "relative-type-1": "el próximo sábado",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} sábado",
              "relativeTimePattern-count-other": "dentro de {0} sábados"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} sábado",
              "relativeTimePattern-count-other": "hace {0} sábados"
            }
          },
          "sat-short": {
            "relative-type--1": "el sáb. pasado",
            "relative-type-0": "este sáb.",
            "relative-type-1": "el próximo sáb.",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} sáb.",
              "relativeTimePattern-count-other": "dentro de {0} sáb."
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} sáb.",
              "relativeTimePattern-count-other": "hace {0} sáb."
            }
          },
          "sat-narrow": {
            "relative-type--1": "el SA pasado",
            "relative-type-0": "este SA",
            "relative-type-1": "el próximo SA",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} SA",
              "relativeTimePattern-count-other": "dentro de {0} SA"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} SA",
              "relativeTimePattern-count-other": "hace {0} SA"
            }
          },
          "dayperiod-short": {
            "displayName": "a. m./p. m."
          },
          "dayperiod": {
            "displayName": "a. m./p. m."
          },
          "dayperiod-narrow": {
            "displayName": "a. m./p. m."
          },
          "hour": {
            "displayName": "hora",
            "relative-type-0": "esta hora",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} hora",
              "relativeTimePattern-count-other": "dentro de {0} horas"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} hora",
              "relativeTimePattern-count-other": "hace {0} horas"
            }
          },
          "hour-short": {
            "displayName": "h",
            "relative-type-0": "esta hora",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} h",
              "relativeTimePattern-count-other": "dentro de {0} h"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} h",
              "relativeTimePattern-count-other": "hace {0} h"
            }
          },
          "hour-narrow": {
            "displayName": "h",
            "relative-type-0": "esta hora",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} h",
              "relativeTimePattern-count-other": "dentro de {0} h"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} h",
              "relativeTimePattern-count-other": "hace {0} h"
            }
          },
          "minute": {
            "displayName": "minuto",
            "relative-type-0": "este minuto",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} minuto",
              "relativeTimePattern-count-other": "dentro de {0} minutos"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} minuto",
              "relativeTimePattern-count-other": "hace {0} minutos"
            }
          },
          "minute-short": {
            "displayName": "min",
            "relative-type-0": "este minuto",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} min",
              "relativeTimePattern-count-other": "dentro de {0} min"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} min",
              "relativeTimePattern-count-other": "hace {0} min"
            }
          },
          "minute-narrow": {
            "displayName": "min",
            "relative-type-0": "este minuto",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} min",
              "relativeTimePattern-count-other": "dentro de {0} min"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} min",
              "relativeTimePattern-count-other": "hace {0} min"
            }
          },
          "second": {
            "displayName": "segundo",
            "relative-type-0": "ahora",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} segundo",
              "relativeTimePattern-count-other": "dentro de {0} segundos"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} segundo",
              "relativeTimePattern-count-other": "hace {0} segundos"
            }
          },
          "second-short": {
            "displayName": "s",
            "relative-type-0": "ahora",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} s",
              "relativeTimePattern-count-other": "dentro de {0} s"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} s",
              "relativeTimePattern-count-other": "hace {0} s"
            }
          },
          "second-narrow": {
            "displayName": "s",
            "relative-type-0": "ahora",
            "relativeTime-type-future": {
              "relativeTimePattern-count-one": "dentro de {0} s",
              "relativeTimePattern-count-other": "dentro de {0} s"
            },
            "relativeTime-type-past": {
              "relativeTimePattern-count-one": "hace {0} s",
              "relativeTimePattern-count-other": "hace {0} s"
            }
          },
          "zone": {
            "displayName": "zona horaria"
          },
          "zone-short": {
            "displayName": "zona"
          },
          "zone-narrow": {
            "displayName": "zona"
          }
        }
      }
    }
  }
}
