{
  "main": {
    "es": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "es"
      },
      "numbers": {
        "currencies": {
          "ADP": {
            "displayName": "peseta andorrana",
            "displayName-count-one": "peseta andorrana",
            "displayName-count-other": "pesetas andorranas",
            "symbol": "ADP"
          },
          "AED": {
            "displayName": "dírham de los Emiratos Árabes Unidos",
            "displayName-count-one": "dírham de los Emiratos Árabes Unidos",
            "displayName-count-other": "dírhams de los Emiratos Árabes Unidos",
            "symbol": "AED"
          },
          "AFA": {
            "displayName": "afgani (1927–2002)",
            "symbol": "AFA"
          },
          "AFN": {
            "displayName": "afgani",
            "displayName-count-one": "afgani",
            "displayName-count-other": "afganis",
            "symbol": "AFN"
          },
          "ALK": {
            "displayName": "ALK",
            "symbol": "ALK"
          },
          "ALL": {
            "displayName": "lek",
            "displayName-count-one": "lek",
            "displayName-count-other": "lekes",
            "symbol": "ALL"
          },
          "AMD": {
            "displayName": "dram",
            "displayName-count-one": "dram",
            "displayName-count-other": "drams",
            "symbol": "AMD"
          },
          "ANG": {
            "displayName": "florín de las Antillas Neerlandesas",
            "displayName-count-one": "florín de las Antillas Neerlandesas",
            "displayName-count-other": "florines de las Antillas Neerlandesas",
            "symbol": "ANG"
          },
          "AOA": {
            "displayName": "kuanza",
            "displayName-count-one": "kuanza",
            "displayName-count-other": "kuanzas",
            "symbol": "AOA",
            "symbol-alt-narrow": "Kz"
          },
          "AOK": {
            "displayName": "kwanza angoleño (1977–1990)",
            "symbol": "AOK"
          },
          "AON": {
            "displayName": "nuevo kwanza angoleño (1990–2000)",
            "symbol": "AON"
          },
          "AOR": {
            "displayName": "kwanza reajustado angoleño (1995–1999)",
            "symbol": "AOR"
          },
          "ARA": {
            "displayName": "austral argentino",
            "displayName-count-one": "austral argentino",
            "displayName-count-other": "australes argentinos",
            "symbol": "ARA"
          },
          "ARL": {
            "displayName": "ARL",
            "symbol": "ARL"
          },
          "ARM": {
            "displayName": "ARM",
            "symbol": "ARM"
          },
          "ARP": {
            "displayName": "peso argentino (1983–1985)",
            "displayName-count-one": "peso argentino (ARP)",
            "displayName-count-other": "pesos argentinos (ARP)",
            "symbol": "ARP"
          },
          "ARS": {
            "displayName": "peso argentino",
            "displayName-count-one": "peso argentino",
            "displayName-count-other": "pesos argentinos",
            "symbol": "ARS",
            "symbol-alt-narrow": "$"
          },
          "ATS": {
            "displayName": "chelín austriaco",
            "displayName-count-one": "chelín austriaco",
            "displayName-count-other": "chelines austriacos",
            "symbol": "ATS"
          },
          "AUD": {
            "displayName": "dólar australiano",
            "displayName-count-one": "dólar australiano",
            "displayName-count-other": "dólares australianos",
            "symbol": "AUD",
            "symbol-alt-narrow": "$"
          },
          "AWG": {
            "displayName": "florín arubeño",
            "displayName-count-one": "florín arubeño",
            "displayName-count-other": "florines arubeños",
            "symbol": "AWG"
          },
          "AZM": {
            "displayName": "manat azerí (1993–2006)",
            "symbol": "AZM"
          },
          "AZN": {
            "displayName": "manat azerbaiyano",
            "displayName-count-one": "manat azerbaiyano",
            "displayName-count-other": "manat azerbaiyanos",
            "symbol": "AZN"
          },
          "BAD": {
            "displayName": "dinar bosnio",
            "displayName-count-one": "dinar bosnio",
            "displayName-count-other": "dinares bosnios",
            "symbol": "BAD"
          },
          "BAM": {
            "displayName": "marco convertible de Bosnia y Herzegovina",
            "displayName-count-one": "marco convertible de Bosnia y Herzegovina",
            "displayName-count-other": "marcos convertibles de Bosnia y Herzegovina",
            "symbol": "BAM",
            "symbol-alt-narrow": "KM"
          },
          "BAN": {
            "displayName": "BAN",
            "symbol": "BAN"
          },
          "BBD": {
            "displayName": "dólar barbadense",
            "displayName-count-one": "dólar barbadense",
            "displayName-count-other": "dólares barbadenses",
            "symbol": "BBD",
            "symbol-alt-narrow": "$"
          },
          "BDT": {
            "displayName": "taka",
            "displayName-count-one": "taka",
            "displayName-count-other": "takas",
            "symbol": "BDT",
            "symbol-alt-narrow": "৳"
          },
          "BEC": {
            "displayName": "franco belga (convertible)",
            "displayName-count-one": "franco belga (convertible)",
            "displayName-count-other": "francos belgas (convertibles)",
            "symbol": "BEC"
          },
          "BEF": {
            "displayName": "franco belga",
            "displayName-count-one": "franco belga",
            "displayName-count-other": "francos belgas",
            "symbol": "BEF"
          },
          "BEL": {
            "displayName": "franco belga (financiero)",
            "displayName-count-one": "franco belga (financiero)",
            "displayName-count-other": "francos belgas (financieros)",
            "symbol": "BEL"
          },
          "BGL": {
            "displayName": "lev fuerte búlgaro",
            "displayName-count-one": "lev fuerte búlgaro",
            "displayName-count-other": "leva fuertes búlgaros",
            "symbol": "BGL"
          },
          "BGM": {
            "displayName": "BGM",
            "symbol": "BGM"
          },
          "BGN": {
            "displayName": "lev búlgaro",
            "displayName-count-one": "lev búlgaro",
            "displayName-count-other": "levas búlgaras",
            "symbol": "BGN"
          },
          "BGO": {
            "displayName": "BGO",
            "symbol": "BGO"
          },
          "BHD": {
            "displayName": "dinar bahreiní",
            "displayName-count-one": "dinar bahreiní",
            "displayName-count-other": "dinares bahreiníes",
            "symbol": "BHD"
          },
          "BIF": {
            "displayName": "franco burundés",
            "displayName-count-one": "franco burundés",
            "displayName-count-other": "francos burundeses",
            "symbol": "BIF"
          },
          "BMD": {
            "displayName": "dólar de Bermudas",
            "displayName-count-one": "dólar de Bermudas",
            "displayName-count-other": "dólares de Bermudas",
            "symbol": "BMD",
            "symbol-alt-narrow": "$"
          },
          "BND": {
            "displayName": "dólar bruneano",
            "displayName-count-one": "dólar bruneano",
            "displayName-count-other": "dólares bruneanos",
            "symbol": "BND",
            "symbol-alt-narrow": "$"
          },
          "BOB": {
            "displayName": "boliviano",
            "displayName-count-one": "boliviano",
            "displayName-count-other": "bolivianos",
            "symbol": "BOB",
            "symbol-alt-narrow": "Bs"
          },
          "BOL": {
            "displayName": "BOL",
            "symbol": "BOL"
          },
          "BOP": {
            "displayName": "peso boliviano",
            "displayName-count-one": "peso boliviano",
            "displayName-count-other": "pesos bolivianos",
            "symbol": "BOP"
          },
          "BOV": {
            "displayName": "MVDOL boliviano",
            "displayName-count-one": "MVDOL boliviano",
            "displayName-count-other": "MVDOL bolivianos",
            "symbol": "BOV"
          },
          "BRB": {
            "displayName": "nuevo cruceiro brasileño (1967–1986)",
            "displayName-count-one": "nuevo cruzado brasileño (BRB)",
            "displayName-count-other": "nuevos cruzados brasileños (BRB)",
            "symbol": "BRB"
          },
          "BRC": {
            "displayName": "cruzado brasileño",
            "displayName-count-one": "cruzado brasileño",
            "displayName-count-other": "cruzados brasileños",
            "symbol": "BRC"
          },
          "BRE": {
            "displayName": "cruceiro brasileño (1990–1993)",
            "displayName-count-one": "cruceiro brasileño (BRE)",
            "displayName-count-other": "cruceiros brasileños (BRE)",
            "symbol": "BRE"
          },
          "BRL": {
            "displayName": "real brasileño",
            "displayName-count-one": "real brasileño",
            "displayName-count-other": "reales brasileños",
            "symbol": "BRL",
            "symbol-alt-narrow": "R$"
          },
          "BRN": {
            "displayName": "nuevo cruzado brasileño",
            "displayName-count-one": "nuevo cruzado brasileño",
            "displayName-count-other": "nuevos cruzados brasileños",
            "symbol": "BRN"
          },
          "BRR": {
            "displayName": "cruceiro brasileño",
            "displayName-count-one": "cruceiro brasileño",
            "displayName-count-other": "cruceiros brasileños",
            "symbol": "BRR"
          },
          "BRZ": {
            "displayName": "BRZ",
            "symbol": "BRZ"
          },
          "BSD": {
            "displayName": "dólar bahameño",
            "displayName-count-one": "dólar bahameño",
            "displayName-count-other": "dólares bahameños",
            "symbol": "BSD",
            "symbol-alt-narrow": "$"
          },
          "BTN": {
            "displayName": "gultrum",
            "displayName-count-one": "gultrum",
            "displayName-count-other": "gultrums",
            "symbol": "BTN"
          },
          "BUK": {
            "displayName": "kyat birmano",
            "displayName-count-one": "kyat birmano",
            "displayName-count-other": "kyat birmanos",
            "symbol": "BUK"
          },
          "BWP": {
            "displayName": "pula",
            "displayName-count-one": "pula",
            "displayName-count-other": "pulas",
            "symbol": "BWP",
            "symbol-alt-narrow": "P"
          },
          "BYB": {
            "displayName": "nuevo rublo bielorruso (1994–1999)",
            "displayName-count-one": "nuevo rublo bielorruso",
            "displayName-count-other": "nuevos rublos bielorrusos",
            "symbol": "BYB"
          },
          "BYN": {
            "displayName": "rublo bielorruso",
            "displayName-count-one": "rublo bielorruso",
            "displayName-count-other": "rublos bielorrusos",
            "symbol": "BYN",
            "symbol-alt-narrow": "р."
          },
          "BYR": {
            "displayName": "rublo bielorruso (2000–2016)",
            "displayName-count-one": "rublo bielorruso (2000–2016)",
            "displayName-count-other": "rublos bielorrusos (2000–2016)",
            "symbol": "BYR"
          },
          "BZD": {
            "displayName": "dólar beliceño",
            "displayName-count-one": "dólar beliceño",
            "displayName-count-other": "dólares beliceños",
            "symbol": "BZD",
            "symbol-alt-narrow": "$"
          },
          "CAD": {
            "displayName": "dólar canadiense",
            "displayName-count-one": "dólar canadiense",
            "displayName-count-other": "dólares canadienses",
            "symbol": "CA$",
            "symbol-alt-narrow": "$"
          },
          "CDF": {
            "displayName": "franco congoleño",
            "displayName-count-one": "franco congoleño",
            "displayName-count-other": "francos congoleños",
            "symbol": "CDF"
          },
          "CHE": {
            "displayName": "euro WIR",
            "displayName-count-one": "euro WIR",
            "displayName-count-other": "euros WIR",
            "symbol": "CHE"
          },
          "CHF": {
            "displayName": "franco suizo",
            "displayName-count-one": "franco suizo",
            "displayName-count-other": "francos suizos",
            "symbol": "CHF"
          },
          "CHW": {
            "displayName": "franco WIR",
            "displayName-count-one": "franco WIR",
            "displayName-count-other": "francos WIR",
            "symbol": "CHW"
          },
          "CLE": {
            "displayName": "CLE",
            "symbol": "CLE"
          },
          "CLF": {
            "displayName": "unidad de fomento chilena",
            "displayName-count-one": "unidad de fomento chilena",
            "displayName-count-other": "unidades de fomento chilenas",
            "symbol": "CLF"
          },
          "CLP": {
            "displayName": "peso chileno",
            "displayName-count-one": "peso chileno",
            "displayName-count-other": "pesos chilenos",
            "symbol": "CLP",
            "symbol-alt-narrow": "$"
          },
          "CNH": {
            "displayName": "yuan chino (extracontinental)",
            "displayName-count-one": "yuan chino (extracontinental)",
            "displayName-count-other": "yuan chino (extracontinental)",
            "symbol": "CNH"
          },
          "CNX": {
            "displayName": "CNX",
            "symbol": "CNX"
          },
          "CNY": {
            "displayName": "yuan",
            "displayName-count-one": "yuan",
            "displayName-count-other": "yuanes",
            "symbol": "CNY",
            "symbol-alt-narrow": "¥"
          },
          "COP": {
            "displayName": "peso colombiano",
            "displayName-count-one": "peso colombiano",
            "displayName-count-other": "pesos colombianos",
            "symbol": "COP",
            "symbol-alt-narrow": "$"
          },
          "COU": {
            "displayName": "unidad de valor real colombiana",
            "displayName-count-one": "unidad de valor real",
            "displayName-count-other": "unidades de valor reales",
            "symbol": "COU"
          },
          "CRC": {
            "displayName": "colón costarricense",
            "displayName-count-one": "colón costarricense",
            "displayName-count-other": "colones costarricenses",
            "symbol": "CRC",
            "symbol-alt-narrow": "₡"
          },
          "CSD": {
            "displayName": "antiguo dinar serbio",
            "displayName-count-one": "antiguo dinar serbio",
            "displayName-count-other": "antiguos dinares serbios",
            "symbol": "CSD"
          },
          "CSK": {
            "displayName": "corona fuerte checoslovaca",
            "displayName-count-one": "corona fuerte checoslovaca",
            "displayName-count-other": "coronas fuertes checoslovacas",
            "symbol": "CSK"
          },
          "CUC": {
            "displayName": "peso cubano convertible",
            "displayName-count-one": "peso cubano convertible",
            "displayName-count-other": "pesos cubanos convertibles",
            "symbol": "CUC",
            "symbol-alt-narrow": "$"
          },
          "CUP": {
            "displayName": "peso cubano",
            "displayName-count-one": "peso cubano",
            "displayName-count-other": "pesos cubanos",
            "symbol": "CUP",
            "symbol-alt-narrow": "$"
          },
          "CVE": {
            "displayName": "escudo de Cabo Verde",
            "displayName-count-one": "escudo de Cabo Verde",
            "displayName-count-other": "escudos de Cabo Verde",
            "symbol": "CVE"
          },
          "CYP": {
            "displayName": "libra chipriota",
            "displayName-count-one": "libra chipriota",
            "displayName-count-other": "libras chipriotas",
            "symbol": "CYP"
          },
          "CZK": {
            "displayName": "corona checa",
            "displayName-count-one": "corona checa",
            "displayName-count-other": "coronas checas",
            "symbol": "CZK",
            "symbol-alt-narrow": "Kč"
          },
          "DDM": {
            "displayName": "ostmark de Alemania del Este",
            "displayName-count-one": "marco de la República Democrática Alemana",
            "displayName-count-other": "marcos de la República Democrática Alemana",
            "symbol": "DDM"
          },
          "DEM": {
            "displayName": "marco alemán",
            "displayName-count-one": "marco alemán",
            "displayName-count-other": "marcos alemanes",
            "symbol": "DEM"
          },
          "DJF": {
            "displayName": "franco yibutiano",
            "displayName-count-one": "franco yibutiano",
            "displayName-count-other": "francos yibutianos",
            "symbol": "DJF"
          },
          "DKK": {
            "displayName": "corona danesa",
            "displayName-count-one": "corona danesa",
            "displayName-count-other": "coronas danesas",
            "symbol": "DKK",
            "symbol-alt-narrow": "kr"
          },
          "DOP": {
            "displayName": "peso dominicano",
            "displayName-count-one": "peso dominicano",
            "displayName-count-other": "pesos dominicanos",
            "symbol": "DOP",
            "symbol-alt-narrow": "$"
          },
          "DZD": {
            "displayName": "dinar argelino",
            "displayName-count-one": "dinar argelino",
            "displayName-count-other": "dinares argelinos",
            "symbol": "DZD"
          },
          "ECS": {
            "displayName": "sucre ecuatoriano",
            "displayName-count-one": "sucre ecuatoriano",
            "displayName-count-other": "sucres ecuatorianos",
            "symbol": "ECS"
          },
          "ECV": {
            "displayName": "unidad de valor constante (UVC) ecuatoriana",
            "displayName-count-one": "unidad de valor constante (UVC) ecuatoriana",
            "displayName-count-other": "unidades de valor constante (UVC) ecuatorianas",
            "symbol": "ECV"
          },
          "EEK": {
            "displayName": "corona estonia",
            "displayName-count-one": "corona estonia",
            "displayName-count-other": "coronas estonias",
            "symbol": "EEK"
          },
          "EGP": {
            "displayName": "libra egipcia",
            "displayName-count-one": "libra egipcia",
            "displayName-count-other": "libras egipcias",
            "symbol": "EGP",
            "symbol-alt-narrow": "EGP"
          },
          "ERN": {
            "displayName": "nakfa",
            "displayName-count-one": "nakfa",
            "displayName-count-other": "nakfas",
            "symbol": "ERN"
          },
          "ESA": {
            "displayName": "peseta española (cuenta A)",
            "displayName-count-one": "peseta española (cuenta A)",
            "displayName-count-other": "pesetas españolas (cuenta A)",
            "symbol": "ESA"
          },
          "ESB": {
            "displayName": "peseta española (cuenta convertible)",
            "displayName-count-one": "peseta española (cuenta convertible)",
            "displayName-count-other": "pesetas españolas (cuenta convertible)",
            "symbol": "ESB"
          },
          "ESP": {
            "displayName": "peseta española",
            "displayName-count-one": "peseta española",
            "displayName-count-other": "pesetas españolas",
            "symbol": "₧",
            "symbol-alt-narrow": "₧"
          },
          "ETB": {
            "displayName": "bir",
            "displayName-count-one": "bir",
            "displayName-count-other": "bires",
            "symbol": "ETB"
          },
          "EUR": {
            "displayName": "euro",
            "displayName-count-one": "euro",
            "displayName-count-other": "euros",
            "symbol": "€",
            "symbol-alt-narrow": "€"
          },
          "FIM": {
            "displayName": "marco finlandés",
            "displayName-count-one": "marco finlandés",
            "displayName-count-other": "marcos finlandeses",
            "symbol": "FIM"
          },
          "FJD": {
            "displayName": "dólar fiyiano",
            "displayName-count-one": "dólar fiyiano",
            "displayName-count-other": "dólares fiyianos",
            "symbol": "FJD",
            "symbol-alt-narrow": "$"
          },
          "FKP": {
            "displayName": "libra malvinense",
            "displayName-count-one": "libra malvinense",
            "displayName-count-other": "libras malvinenses",
            "symbol": "FKP",
            "symbol-alt-narrow": "£"
          },
          "FRF": {
            "displayName": "franco francés",
            "displayName-count-one": "franco francés",
            "displayName-count-other": "francos franceses",
            "symbol": "FRF"
          },
          "GBP": {
            "displayName": "libra esterlina",
            "displayName-count-one": "libra esterlina",
            "displayName-count-other": "libras esterlinas",
            "symbol": "GBP",
            "symbol-alt-narrow": "£"
          },
          "GEK": {
            "displayName": "kupon larit georgiano",
            "symbol": "GEK"
          },
          "GEL": {
            "displayName": "lari",
            "displayName-count-one": "lari",
            "displayName-count-other": "laris",
            "symbol": "GEL",
            "symbol-alt-narrow": "₾",
            "symbol-alt-variant": "₾"
          },
          "GHC": {
            "displayName": "cedi ghanés (1979–2007)",
            "symbol": "GHC"
          },
          "GHS": {
            "displayName": "cedi",
            "displayName-count-one": "cedi",
            "displayName-count-other": "cedis",
            "symbol": "GHS"
          },
          "GIP": {
            "displayName": "libra gibraltareña",
            "displayName-count-one": "libra gibraltareña",
            "displayName-count-other": "libras gibraltareñas",
            "symbol": "GIP",
            "symbol-alt-narrow": "£"
          },
          "GMD": {
            "displayName": "dalasi",
            "displayName-count-one": "dalasi",
            "displayName-count-other": "dalasis",
            "symbol": "GMD"
          },
          "GNF": {
            "displayName": "franco guineano",
            "displayName-count-one": "franco guineano",
            "displayName-count-other": "francos guineanos",
            "symbol": "GNF",
            "symbol-alt-narrow": "FG"
          },
          "GNS": {
            "displayName": "syli guineano",
            "symbol": "GNS"
          },
          "GQE": {
            "displayName": "ekuele de Guinea Ecuatorial",
            "displayName-count-one": "ekuele de Guinea Ecuatorial",
            "displayName-count-other": "ekueles de Guinea Ecuatorial",
            "symbol": "GQE"
          },
          "GRD": {
            "displayName": "dracma griego",
            "displayName-count-one": "dracma griego",
            "displayName-count-other": "dracmas griegos",
            "symbol": "GRD"
          },
          "GTQ": {
            "displayName": "quetzal guatemalteco",
            "displayName-count-one": "quetzal guatemalteco",
            "displayName-count-other": "quetzales guatemaltecos",
            "symbol": "GTQ",
            "symbol-alt-narrow": "Q"
          },
          "GWE": {
            "displayName": "escudo de Guinea Portuguesa",
            "symbol": "GWE"
          },
          "GWP": {
            "displayName": "peso de Guinea-Bissáu",
            "symbol": "GWP"
          },
          "GYD": {
            "displayName": "dólar guyanés",
            "displayName-count-one": "dólar guyanés",
            "displayName-count-other": "dólares guyaneses",
            "symbol": "GYD",
            "symbol-alt-narrow": "$"
          },
          "HKD": {
            "displayName": "dólar hongkonés",
            "displayName-count-one": "dólar hongkonés",
            "displayName-count-other": "dólares hongkoneses",
            "symbol": "HKD",
            "symbol-alt-narrow": "$"
          },
          "HNL": {
            "displayName": "lempira hondureño",
            "displayName-count-one": "lempira hondureño",
            "displayName-count-other": "lempiras hondureños",
            "symbol": "HNL",
            "symbol-alt-narrow": "L"
          },
          "HRD": {
            "displayName": "dinar croata",
            "displayName-count-one": "dinar croata",
            "displayName-count-other": "dinares croatas",
            "symbol": "HRD"
          },
          "HRK": {
            "displayName": "kuna",
            "displayName-count-one": "kuna",
            "displayName-count-other": "kunas",
            "symbol": "HRK",
            "symbol-alt-narrow": "kn"
          },
          "HTG": {
            "displayName": "gourde haitiano",
            "displayName-count-one": "gourde haitiano",
            "displayName-count-other": "gourdes haitianos",
            "symbol": "HTG"
          },
          "HUF": {
            "displayName": "forinto húngaro",
            "displayName-count-one": "forinto húngaro",
            "displayName-count-other": "forintos húngaros",
            "symbol": "HUF",
            "symbol-alt-narrow": "Ft"
          },
          "IDR": {
            "displayName": "rupia indonesia",
            "displayName-count-one": "rupia indonesia",
            "displayName-count-other": "rupias indonesias",
            "symbol": "IDR",
            "symbol-alt-narrow": "Rp"
          },
          "IEP": {
            "displayName": "libra irlandesa",
            "displayName-count-one": "libra irlandesa",
            "displayName-count-other": "libras irlandesas",
            "symbol": "IEP"
          },
          "ILP": {
            "displayName": "libra israelí",
            "displayName-count-one": "libra israelí",
            "displayName-count-other": "libras israelíes",
            "symbol": "ILP"
          },
          "ILR": {
            "displayName": "ILR",
            "symbol": "ILR"
          },
          "ILS": {
            "displayName": "nuevo séquel israelí",
            "displayName-count-one": "nuevo séquel israelí",
            "displayName-count-other": "nuevos séqueles israelíes",
            "symbol": "ILS",
            "symbol-alt-narrow": "₪"
          },
          "INR": {
            "displayName": "rupia india",
            "displayName-count-one": "rupia india",
            "displayName-count-other": "rupias indias",
            "symbol": "INR",
            "symbol-alt-narrow": "₹"
          },
          "IQD": {
            "displayName": "dinar iraquí",
            "displayName-count-one": "dinar iraquí",
            "displayName-count-other": "dinares iraquíes",
            "symbol": "IQD"
          },
          "IRR": {
            "displayName": "rial iraní",
            "displayName-count-one": "rial iraní",
            "displayName-count-other": "riales iraníes",
            "symbol": "IRR"
          },
          "ISJ": {
            "displayName": "ISJ",
            "symbol": "ISJ"
          },
          "ISK": {
            "displayName": "corona islandesa",
            "displayName-count-one": "corona islandesa",
            "displayName-count-other": "coronas islandesas",
            "symbol": "ISK",
            "symbol-alt-narrow": "kr"
          },
          "ITL": {
            "displayName": "lira italiana",
            "displayName-count-one": "lira italiana",
            "displayName-count-other": "liras italianas",
            "symbol": "ITL"
          },
          "JMD": {
            "displayName": "dólar jamaicano",
            "displayName-count-one": "dólar jamaicano",
            "displayName-count-other": "dólares jamaicanos",
            "symbol": "JMD",
            "symbol-alt-narrow": "$"
          },
          "JOD": {
            "displayName": "dinar jordano",
            "displayName-count-one": "dinar jordano",
            "displayName-count-other": "dinares jordanos",
            "symbol": "JOD"
          },
          "JPY": {
            "displayName": "yen",
            "displayName-count-one": "yen",
            "displayName-count-other": "yenes",
            "symbol": "JPY",
            "symbol-alt-narrow": "¥"
          },
          "KES": {
            "displayName": "chelín keniano",
            "displayName-count-one": "chelín keniano",
            "displayName-count-other": "chelines kenianos",
            "symbol": "KES"
          },
          "KGS": {
            "displayName": "som",
            "displayName-count-one": "som",
            "displayName-count-other": "soms",
            "symbol": "KGS"
          },
          "KHR": {
            "displayName": "riel",
            "displayName-count-one": "riel",
            "displayName-count-other": "rieles",
            "symbol": "KHR",
            "symbol-alt-narrow": "៛"
          },
          "KMF": {
            "displayName": "franco comorense",
            "displayName-count-one": "franco comorense",
            "displayName-count-other": "francos comorenses",
            "symbol": "KMF",
            "symbol-alt-narrow": "CF"
          },
          "KPW": {
            "displayName": "won norcoreano",
            "displayName-count-one": "won norcoreano",
            "displayName-count-other": "wons norcoreanos",
            "symbol": "KPW",
            "symbol-alt-narrow": "₩"
          },
          "KRH": {
            "displayName": "KRH",
            "symbol": "KRH"
          },
          "KRO": {
            "displayName": "KRO",
            "symbol": "KRO"
          },
          "KRW": {
            "displayName": "won surcoreano",
            "displayName-count-one": "won surcoreano",
            "displayName-count-other": "wons surcoreanos",
            "symbol": "KRW",
            "symbol-alt-narrow": "₩"
          },
          "KWD": {
            "displayName": "dinar kuwaití",
            "displayName-count-one": "dinar kuwaití",
            "displayName-count-other": "dinares kuwaitíes",
            "symbol": "KWD"
          },
          "KYD": {
            "displayName": "dólar de las Islas Caimán",
            "displayName-count-one": "dólar de las Islas Caimán",
            "displayName-count-other": "dólares de las Islas Caimán",
            "symbol": "KYD",
            "symbol-alt-narrow": "$"
          },
          "KZT": {
            "displayName": "tenge kazako",
            "displayName-count-one": "tenge kazako",
            "displayName-count-other": "tenges kazakos",
            "symbol": "KZT",
            "symbol-alt-narrow": "₸"
          },
          "LAK": {
            "displayName": "kip",
            "displayName-count-one": "kip",
            "displayName-count-other": "kips",
            "symbol": "LAK",
            "symbol-alt-narrow": "₭"
          },
          "LBP": {
            "displayName": "libra libanesa",
            "displayName-count-one": "libra libanesa",
            "displayName-count-other": "libras libanesas",
            "symbol": "LBP",
            "symbol-alt-narrow": "L£"
          },
          "LKR": {
            "displayName": "rupia esrilanquesa",
            "displayName-count-one": "rupia esrilanquesa",
            "displayName-count-other": "rupias esrilanquesas",
            "symbol": "LKR",
            "symbol-alt-narrow": "Rs"
          },
          "LRD": {
            "displayName": "dólar liberiano",
            "displayName-count-one": "dólar liberiano",
            "displayName-count-other": "dólares liberianos",
            "symbol": "LRD",
            "symbol-alt-narrow": "$"
          },
          "LSL": {
            "displayName": "loti lesothense",
            "symbol": "LSL"
          },
          "LTL": {
            "displayName": "litas lituano",
            "displayName-count-one": "litas lituana",
            "displayName-count-other": "litas lituanas",
            "symbol": "LTL",
            "symbol-alt-narrow": "Lt"
          },
          "LTT": {
            "displayName": "talonas lituano",
            "displayName-count-one": "talonas lituana",
            "displayName-count-other": "talonas lituanas",
            "symbol": "LTT"
          },
          "LUC": {
            "displayName": "franco convertible luxemburgués",
            "displayName-count-one": "franco convertible luxemburgués",
            "displayName-count-other": "francos convertibles luxemburgueses",
            "symbol": "LUC"
          },
          "LUF": {
            "displayName": "franco luxemburgués",
            "displayName-count-one": "franco luxemburgués",
            "displayName-count-other": "francos luxemburgueses",
            "symbol": "LUF"
          },
          "LUL": {
            "displayName": "franco financiero luxemburgués",
            "displayName-count-one": "franco financiero luxemburgués",
            "displayName-count-other": "francos financieros luxemburgueses",
            "symbol": "LUL"
          },
          "LVL": {
            "displayName": "lats letón",
            "displayName-count-one": "lats letón",
            "displayName-count-other": "lats letónes",
            "symbol": "LVL",
            "symbol-alt-narrow": "Ls"
          },
          "LVR": {
            "displayName": "rublo letón",
            "displayName-count-one": "rublo letón",
            "displayName-count-other": "rublos letones",
            "symbol": "LVR"
          },
          "LYD": {
            "displayName": "dinar libio",
            "displayName-count-one": "dinar libio",
            "displayName-count-other": "dinares libios",
            "symbol": "LYD"
          },
          "MAD": {
            "displayName": "dírham marroquí",
            "displayName-count-one": "dírham marroquí",
            "displayName-count-other": "dírhams marroquíes",
            "symbol": "MAD"
          },
          "MAF": {
            "displayName": "franco marroquí",
            "displayName-count-one": "franco marroquí",
            "displayName-count-other": "francos marroquíes",
            "symbol": "MAF"
          },
          "MCF": {
            "displayName": "MCF",
            "symbol": "MCF"
          },
          "MDC": {
            "displayName": "MDC",
            "symbol": "MDC"
          },
          "MDL": {
            "displayName": "leu moldavo",
            "displayName-count-one": "leu moldavo",
            "displayName-count-other": "lei moldavos",
            "symbol": "MDL"
          },
          "MGA": {
            "displayName": "ariari",
            "displayName-count-one": "ariari",
            "displayName-count-other": "ariaris",
            "symbol": "MGA",
            "symbol-alt-narrow": "Ar"
          },
          "MGF": {
            "displayName": "franco malgache",
            "symbol": "MGF"
          },
          "MKD": {
            "displayName": "dinar macedonio",
            "displayName-count-one": "dinar macedonio",
            "displayName-count-other": "dinares macedonios",
            "symbol": "MKD"
          },
          "MKN": {
            "displayName": "MKN",
            "symbol": "MKN"
          },
          "MLF": {
            "displayName": "franco malí",
            "symbol": "MLF"
          },
          "MMK": {
            "displayName": "kiat",
            "displayName-count-one": "kiat",
            "displayName-count-other": "kiats",
            "symbol": "MMK",
            "symbol-alt-narrow": "K"
          },
          "MNT": {
            "displayName": "tugrik",
            "displayName-count-one": "tugrik",
            "displayName-count-other": "tugriks",
            "symbol": "MNT",
            "symbol-alt-narrow": "₮"
          },
          "MOP": {
            "displayName": "pataca de Macao",
            "displayName-count-one": "pataca de Macao",
            "displayName-count-other": "patacas de Macao",
            "symbol": "MOP"
          },
          "MRO": {
            "displayName": "uguiya (1973–2017)",
            "displayName-count-one": "uguiya (1973–2017)",
            "displayName-count-other": "uguiyas (1973–2017)",
            "symbol": "MRO"
          },
          "MRU": {
            "displayName": "uguiya",
            "displayName-count-one": "uguiya",
            "displayName-count-other": "uguiyas",
            "symbol": "MRU"
          },
          "MTL": {
            "displayName": "lira maltesa",
            "displayName-count-one": "lira maltesa",
            "displayName-count-other": "liras maltesas",
            "symbol": "MTL"
          },
          "MTP": {
            "displayName": "libra maltesa",
            "displayName-count-one": "libra maltesa",
            "displayName-count-other": "libras maltesas",
            "symbol": "MTP"
          },
          "MUR": {
            "displayName": "rupia mauriciana",
            "displayName-count-one": "rupia mauriciana",
            "displayName-count-other": "rupias mauricianas",
            "symbol": "MUR",
            "symbol-alt-narrow": "Rs"
          },
          "MVP": {
            "displayName": "MVP",
            "symbol": "MVP"
          },
          "MVR": {
            "displayName": "rufiya",
            "displayName-count-one": "rufiya",
            "displayName-count-other": "rufiyas",
            "symbol": "MVR"
          },
          "MWK": {
            "displayName": "kuacha malauí",
            "displayName-count-one": "kuacha malauí",
            "displayName-count-other": "kuachas malauís",
            "symbol": "MWK"
          },
          "MXN": {
            "displayName": "peso mexicano",
            "displayName-count-one": "peso mexicano",
            "displayName-count-other": "pesos mexicanos",
            "symbol": "MXN",
            "symbol-alt-narrow": "$"
          },
          "MXP": {
            "displayName": "peso de plata mexicano (1861–1992)",
            "displayName-count-one": "peso de plata mexicano (MXP)",
            "displayName-count-other": "pesos de plata mexicanos (MXP)",
            "symbol": "MXP"
          },
          "MXV": {
            "displayName": "unidad de inversión (UDI) mexicana",
            "displayName-count-one": "unidad de inversión (UDI) mexicana",
            "displayName-count-other": "unidades de inversión (UDI) mexicanas",
            "symbol": "MXV"
          },
          "MYR": {
            "displayName": "ringit",
            "displayName-count-one": "ringit",
            "displayName-count-other": "ringits",
            "symbol": "MYR",
            "symbol-alt-narrow": "RM"
          },
          "MZE": {
            "displayName": "escudo mozambiqueño",
            "displayName-count-one": "escudo mozambiqueño",
            "displayName-count-other": "escudos mozambiqueños",
            "symbol": "MZE"
          },
          "MZM": {
            "displayName": "antiguo metical mozambiqueño",
            "symbol": "MZM"
          },
          "MZN": {
            "displayName": "metical",
            "displayName-count-one": "metical",
            "displayName-count-other": "meticales",
            "symbol": "MZN"
          },
          "NAD": {
            "displayName": "dólar namibio",
            "displayName-count-one": "dólar namibio",
            "displayName-count-other": "dólares namibios",
            "symbol": "NAD",
            "symbol-alt-narrow": "$"
          },
          "NGN": {
            "displayName": "naira",
            "displayName-count-one": "naira",
            "displayName-count-other": "nairas",
            "symbol": "NGN",
            "symbol-alt-narrow": "₦"
          },
          "NIC": {
            "displayName": "córdoba nicaragüense (1988–1991)",
            "displayName-count-one": "córdoba nicaragüense (1988–1991)",
            "displayName-count-other": "córdobas nicaragüenses (1988–1991)",
            "symbol": "NIC"
          },
          "NIO": {
            "displayName": "córdoba nicaragüense",
            "displayName-count-one": "córdoba nicaragüense",
            "displayName-count-other": "córdobas nicaragüenses",
            "symbol": "NIO",
            "symbol-alt-narrow": "C$"
          },
          "NLG": {
            "displayName": "florín neerlandés",
            "displayName-count-one": "florín neerlandés",
            "displayName-count-other": "florines neerlandeses",
            "symbol": "NLG"
          },
          "NOK": {
            "displayName": "corona noruega",
            "displayName-count-one": "corona noruega",
            "displayName-count-other": "coronas noruegas",
            "symbol": "NOK",
            "symbol-alt-narrow": "kr"
          },
          "NPR": {
            "displayName": "rupia nepalí",
            "displayName-count-one": "rupia nepalí",
            "displayName-count-other": "rupias nepalíes",
            "symbol": "NPR",
            "symbol-alt-narrow": "Rs"
          },
          "NZD": {
            "displayName": "dólar neozelandés",
            "displayName-count-one": "dólar neozelandés",
            "displayName-count-other": "dólares neozelandeses",
            "symbol": "NZD",
            "symbol-alt-narrow": "$"
          },
          "OMR": {
            "displayName": "rial omaní",
            "displayName-count-one": "rial omaní",
            "displayName-count-other": "riales omaníes",
            "symbol": "OMR"
          },
          "PAB": {
            "displayName": "balboa panameño",
            "displayName-count-one": "balboa panameño",
            "displayName-count-other": "balboas panameños",
            "symbol": "PAB"
          },
          "PEI": {
            "displayName": "inti peruano",
            "displayName-count-one": "inti peruano",
            "displayName-count-other": "intis peruanos",
            "symbol": "PEI"
          },
          "PEN": {
            "displayName": "sol peruano",
            "displayName-count-one": "sol peruano",
            "displayName-count-other": "soles peruanos",
            "symbol": "PEN"
          },
          "PES": {
            "displayName": "sol peruano (1863–1965)",
            "displayName-count-one": "sol peruano (1863–1965)",
            "displayName-count-other": "soles peruanos (1863–1965)",
            "symbol": "PES"
          },
          "PGK": {
            "displayName": "kina",
            "displayName-count-one": "kina",
            "displayName-count-other": "kinas",
            "symbol": "PGK"
          },
          "PHP": {
            "displayName": "peso filipino",
            "displayName-count-one": "peso filipino",
            "displayName-count-other": "pesos filipinos",
            "symbol": "PHP",
            "symbol-alt-narrow": "₱"
          },
          "PKR": {
            "displayName": "rupia pakistaní",
            "displayName-count-one": "rupia pakistaní",
            "displayName-count-other": "rupias pakistaníes",
            "symbol": "PKR",
            "symbol-alt-narrow": "Rs"
          },
          "PLN": {
            "displayName": "esloti",
            "displayName-count-one": "esloti",
            "displayName-count-other": "eslotis",
            "symbol": "PLN",
            "symbol-alt-narrow": "zł"
          },
          "PLZ": {
            "displayName": "zloty polaco (1950–1995)",
            "displayName-count-one": "zloty polaco (PLZ)",
            "displayName-count-other": "zlotys polacos (PLZ)",
            "symbol": "PLZ"
          },
          "PTE": {
            "displayName": "escudo portugués",
            "displayName-count-one": "escudo portugués",
            "displayName-count-other": "escudos portugueses",
            "symbol": "PTE"
          },
          "PYG": {
            "displayName": "guaraní paraguayo",
            "displayName-count-one": "guaraní paraguayo",
            "displayName-count-other": "guaraníes paraguayos",
            "symbol": "PYG",
            "symbol-alt-narrow": "₲"
          },
          "QAR": {
            "displayName": "rial catarí",
            "displayName-count-one": "rial catarí",
            "displayName-count-other": "riales cataríes",
            "symbol": "QAR"
          },
          "RHD": {
            "displayName": "dólar rodesiano",
            "symbol": "RHD"
          },
          "ROL": {
            "displayName": "antiguo leu rumano",
            "displayName-count-one": "antiguo leu rumano",
            "displayName-count-other": "antiguos lei rumanos",
            "symbol": "ROL"
          },
          "RON": {
            "displayName": "leu rumano",
            "displayName-count-one": "leu rumano",
            "displayName-count-other": "lei rumanos",
            "symbol": "RON",
            "symbol-alt-narrow": "L"
          },
          "RSD": {
            "displayName": "dinar serbio",
            "displayName-count-one": "dinar serbio",
            "displayName-count-other": "dinares serbios",
            "symbol": "RSD"
          },
          "RUB": {
            "displayName": "rublo ruso",
            "displayName-count-one": "rublo ruso",
            "displayName-count-other": "rublos rusos",
            "symbol": "RUB",
            "symbol-alt-narrow": "₽"
          },
          "RUR": {
            "displayName": "rublo ruso (1991–1998)",
            "displayName-count-one": "rublo ruso (RUR)",
            "displayName-count-other": "rublos rusos (RUR)",
            "symbol": "RUR",
            "symbol-alt-narrow": "р."
          },
          "RWF": {
            "displayName": "franco ruandés",
            "displayName-count-one": "franco ruandés",
            "displayName-count-other": "francos ruandeses",
            "symbol": "RWF",
            "symbol-alt-narrow": "RF"
          },
          "SAR": {
            "displayName": "rial saudí",
            "displayName-count-one": "rial saudí",
            "displayName-count-other": "riales saudíes",
            "symbol": "SAR"
          },
          "SBD": {
            "displayName": "dólar salomonense",
            "displayName-count-one": "dólar salomonense",
            "displayName-count-other": "dólares salomonenses",
            "symbol": "SBD",
            "symbol-alt-narrow": "$"
          },
          "SCR": {
            "displayName": "rupia seychellense",
            "displayName-count-one": "rupia seychellense",
            "displayName-count-other": "rupias seychellenses",
            "symbol": "SCR"
          },
          "SDD": {
            "displayName": "dinar sudanés",
            "displayName-count-one": "dinar sudanés",
            "displayName-count-other": "dinares sudaneses",
            "symbol": "SDD"
          },
          "SDG": {
            "displayName": "libra sudanesa",
            "displayName-count-one": "libra sudanesa",
            "displayName-count-other": "libras sudanesas",
            "symbol": "SDG"
          },
          "SDP": {
            "displayName": "libra sudanesa antigua",
            "displayName-count-one": "libra sudanesa antigua",
            "displayName-count-other": "libras sudanesas antiguas",
            "symbol": "SDP"
          },
          "SEK": {
            "displayName": "corona sueca",
            "displayName-count-one": "corona sueca",
            "displayName-count-other": "coronas suecas",
            "symbol": "SEK",
            "symbol-alt-narrow": "kr"
          },
          "SGD": {
            "displayName": "dólar singapurense",
            "displayName-count-one": "dólar singapurense",
            "displayName-count-other": "dólares singapurenses",
            "symbol": "SGD",
            "symbol-alt-narrow": "$"
          },
          "SHP": {
            "displayName": "libra de Santa Elena",
            "displayName-count-one": "libra de Santa Elena",
            "displayName-count-other": "libras de Santa Elena",
            "symbol": "SHP",
            "symbol-alt-narrow": "£"
          },
          "SIT": {
            "displayName": "tólar esloveno",
            "displayName-count-one": "tólar esloveno",
            "displayName-count-other": "tólares eslovenos",
            "symbol": "SIT"
          },
          "SKK": {
            "displayName": "corona eslovaca",
            "displayName-count-one": "corona eslovaca",
            "displayName-count-other": "coronas eslovacas",
            "symbol": "SKK"
          },
          "SLL": {
            "displayName": "leona",
            "displayName-count-one": "leona",
            "displayName-count-other": "leonas",
            "symbol": "SLL"
          },
          "SOS": {
            "displayName": "chelín somalí",
            "displayName-count-one": "chelín somalí",
            "displayName-count-other": "chelines somalíes",
            "symbol": "SOS"
          },
          "SRD": {
            "displayName": "dólar surinamés",
            "displayName-count-one": "dólar surinamés",
            "displayName-count-other": "dólares surinameses",
            "symbol": "SRD",
            "symbol-alt-narrow": "$"
          },
          "SRG": {
            "displayName": "florín surinamés",
            "symbol": "SRG"
          },
          "SSP": {
            "displayName": "libra sursudanesa",
            "displayName-count-one": "libra sursudanesa",
            "displayName-count-other": "libras sursudanesas",
            "symbol": "SSP",
            "symbol-alt-narrow": "£"
          },
          "STD": {
            "displayName": "dobra (1977–2017)",
            "displayName-count-one": "dobra (1977–2017)",
            "displayName-count-other": "dobras (1977–2017)",
            "symbol": "STD"
          },
          "STN": {
            "displayName": "dobra",
            "displayName-count-one": "dobra",
            "displayName-count-other": "dobras",
            "symbol": "STN",
            "symbol-alt-narrow": "Db"
          },
          "SUR": {
            "displayName": "rublo soviético",
            "displayName-count-one": "rublo soviético",
            "displayName-count-other": "rublos soviéticos",
            "symbol": "SUR"
          },
          "SVC": {
            "displayName": "colón salvadoreño",
            "displayName-count-one": "colón salvadoreño",
            "displayName-count-other": "colones salvadoreños",
            "symbol": "SVC"
          },
          "SYP": {
            "displayName": "libra siria",
            "displayName-count-one": "libra siria",
            "displayName-count-other": "libras sirias",
            "symbol": "SYP",
            "symbol-alt-narrow": "£"
          },
          "SZL": {
            "displayName": "lilangeni",
            "displayName-count-one": "lilangeni",
            "displayName-count-other": "lilangenis",
            "symbol": "SZL"
          },
          "THB": {
            "displayName": "bat",
            "displayName-count-one": "bat",
            "displayName-count-other": "bats",
            "symbol": "฿",
            "symbol-alt-narrow": "฿"
          },
          "TJR": {
            "displayName": "rublo tayiko",
            "symbol": "TJR"
          },
          "TJS": {
            "displayName": "somoni tayiko",
            "displayName-count-one": "somoni tayiko",
            "displayName-count-other": "somonis tayikos",
            "symbol": "TJS"
          },
          "TMM": {
            "displayName": "manat turcomano (1993–2009)",
            "displayName-count-one": "manat turcomano (1993–2009)",
            "displayName-count-other": "manats turcomanos (1993–2009)",
            "symbol": "TMM"
          },
          "TMT": {
            "displayName": "manat turcomano",
            "displayName-count-one": "manat turcomano",
            "displayName-count-other": "manat turcomanos",
            "symbol": "TMT"
          },
          "TND": {
            "displayName": "dinar tunecino",
            "displayName-count-one": "dinar tunecino",
            "displayName-count-other": "dinares tunecinos",
            "symbol": "TND"
          },
          "TOP": {
            "displayName": "paanga",
            "displayName-count-one": "paanga",
            "displayName-count-other": "paangas",
            "symbol": "TOP",
            "symbol-alt-narrow": "T$"
          },
          "TPE": {
            "displayName": "escudo timorense",
            "symbol": "TPE"
          },
          "TRL": {
            "displayName": "lira turca (1922–2005)",
            "displayName-count-one": "lira turca (1922–2005)",
            "displayName-count-other": "liras turcas (1922–2005)",
            "symbol": "TRL"
          },
          "TRY": {
            "displayName": "lira turca",
            "displayName-count-one": "lira turca",
            "displayName-count-other": "liras turcas",
            "symbol": "TRY",
            "symbol-alt-narrow": "₺",
            "symbol-alt-variant": "TL"
          },
          "TTD": {
            "displayName": "dólar de Trinidad y Tobago",
            "displayName-count-one": "dólar de Trinidad y Tobago",
            "displayName-count-other": "dólares de Trinidad y Tobago",
            "symbol": "TTD",
            "symbol-alt-narrow": "$"
          },
          "TWD": {
            "displayName": "nuevo dólar taiwanés",
            "displayName-count-one": "nuevo dólar taiwanés",
            "displayName-count-other": "nuevos dólares taiwaneses",
            "symbol": "TWD",
            "symbol-alt-narrow": "NT$"
          },
          "TZS": {
            "displayName": "chelín tanzano",
            "displayName-count-one": "chelín tanzano",
            "displayName-count-other": "chelines tanzanos",
            "symbol": "TZS"
          },
          "UAH": {
            "displayName": "grivna",
            "displayName-count-one": "grivna",
            "displayName-count-other": "grivnas",
            "symbol": "UAH",
            "symbol-alt-narrow": "₴"
          },
          "UAK": {
            "displayName": "karbovanet ucraniano",
            "displayName-count-one": "karbovanet ucraniano",
            "displayName-count-other": "karbovanets ucranianos",
            "symbol": "UAK"
          },
          "UGS": {
            "displayName": "chelín ugandés (1966–1987)",
            "symbol": "UGS"
          },
          "UGX": {
            "displayName": "chelín ugandés",
            "displayName-count-one": "chelín ugandés",
            "displayName-count-other": "chelines ugandeses",
            "symbol": "UGX"
          },
          "USD": {
            "displayName": "dólar estadounidense",
            "displayName-count-one": "dólar estadounidense",
            "displayName-count-other": "dólares estadounidenses",
            "symbol": "US$",
            "symbol-alt-narrow": "$"
          },
          "USN": {
            "displayName": "dólar estadounidense (día siguiente)",
            "displayName-count-one": "dólar estadounidense (día siguiente)",
            "displayName-count-other": "dólares estadounidenses (día siguiente)",
            "symbol": "USN"
          },
          "USS": {
            "displayName": "dólar estadounidense (mismo día)",
            "displayName-count-one": "dólar estadounidense (mismo día)",
            "displayName-count-other": "dólares estadounidenses (mismo día)",
            "symbol": "USS"
          },
          "UYI": {
            "displayName": "peso uruguayo en unidades indexadas",
            "displayName-count-one": "peso uruguayo en unidades indexadas",
            "displayName-count-other": "pesos uruguayos en unidades indexadas",
            "symbol": "UYI"
          },
          "UYP": {
            "displayName": "peso uruguayo (1975–1993)",
            "displayName-count-one": "peso uruguayo (UYP)",
            "displayName-count-other": "pesos uruguayos (UYP)",
            "symbol": "UYP"
          },
          "UYU": {
            "displayName": "peso uruguayo",
            "displayName-count-one": "peso uruguayo",
            "displayName-count-other": "pesos uruguayos",
            "symbol": "UYU",
            "symbol-alt-narrow": "$"
          },
          "UYW": {
            "displayName": "unidad previsional uruguayo",
            "displayName-count-one": "unidad previsional uruguayo",
            "displayName-count-other": "unidades previsionales uruguayos",
            "symbol": "UYW"
          },
          "UZS": {
            "displayName": "sum",
            "displayName-count-one": "sum",
            "displayName-count-other": "sums",
            "symbol": "UZS"
          },
          "VEB": {
            "displayName": "bolívar venezolano (1871–2008)",
            "displayName-count-one": "bolívar venezolano (1871–2008)",
            "displayName-count-other": "bolívares venezolanos (1871–2008)",
            "symbol": "VEB"
          },
          "VEF": {
            "displayName": "bolívar venezolano (2008–2018)",
            "displayName-count-one": "bolívar venezolano (2008–2018)",
            "displayName-count-other": "bolívares venezolanos (2008–2018)",
            "symbol": "VEF",
            "symbol-alt-narrow": "Bs"
          },
          "VES": {
            "displayName": "bolívar venezolano",
            "displayName-count-one": "bolívar venezolano",
            "displayName-count-other": "bolívares venezolanos",
            "symbol": "VES"
          },
          "VND": {
            "displayName": "dong",
            "displayName-count-one": "dong",
            "displayName-count-other": "dongs",
            "symbol": "₫",
            "symbol-alt-narrow": "₫"
          },
          "VNN": {
            "displayName": "VNN",
            "symbol": "VNN"
          },
          "VUV": {
            "displayName": "vatu",
            "displayName-count-one": "vatu",
            "displayName-count-other": "vatus",
            "symbol": "VUV"
          },
          "WST": {
            "displayName": "tala",
            "displayName-count-one": "tala",
            "displayName-count-other": "talas",
            "symbol": "WST"
          },
          "XAF": {
            "displayName": "franco CFA de África Central",
            "displayName-count-one": "franco CFA de África Central",
            "displayName-count-other": "francos CFA de África Central",
            "symbol": "XAF"
          },
          "XAG": {
            "displayName": "plata",
            "displayName-count-one": "plata",
            "displayName-count-other": "plata",
            "symbol": "XAG"
          },
          "XAU": {
            "displayName": "oro",
            "displayName-count-one": "oro",
            "displayName-count-other": "oro",
            "symbol": "XAU"
          },
          "XBA": {
            "displayName": "unidad compuesta europea",
            "displayName-count-one": "unidad compuesta europea",
            "displayName-count-other": "unidades compuestas europeas",
            "symbol": "XBA"
          },
          "XBB": {
            "displayName": "unidad monetaria europea",
            "displayName-count-one": "unidad monetaria europea",
            "displayName-count-other": "unidades monetarias europeas",
            "symbol": "XBB"
          },
          "XBC": {
            "displayName": "unidad de cuenta europea (XBC)",
            "displayName-count-one": "unidad de cuenta europea (XBC)",
            "displayName-count-other": "unidades de cuenta europeas (XBC)",
            "symbol": "XBC"
          },
          "XBD": {
            "displayName": "unidad de cuenta europea (XBD)",
            "displayName-count-one": "unidad de cuenta europea (XBD)",
            "displayName-count-other": "unidades de cuenta europeas (XBD)",
            "symbol": "XBD"
          },
          "XCD": {
            "displayName": "dólar del Caribe Oriental",
            "displayName-count-one": "dólar del Caribe Oriental",
            "displayName-count-other": "dólares del Caribe Oriental",
            "symbol": "XCD",
            "symbol-alt-narrow": "$"
          },
          "XDR": {
            "displayName": "derechos especiales de giro",
            "symbol": "XDR"
          },
          "XEU": {
            "displayName": "unidad de moneda europea",
            "displayName-count-one": "unidad de moneda europea",
            "displayName-count-other": "unidades de moneda europeas",
            "symbol": "XEU"
          },
          "XFO": {
            "displayName": "franco oro francés",
            "displayName-count-one": "franco oro francés",
            "displayName-count-other": "francos oro franceses",
            "symbol": "XFO"
          },
          "XFU": {
            "displayName": "franco UIC francés",
            "displayName-count-one": "franco UIC francés",
            "displayName-count-other": "francos UIC franceses",
            "symbol": "XFU"
          },
          "XOF": {
            "displayName": "franco CFA de África Occidental",
            "displayName-count-one": "franco CFA de África Occidental",
            "displayName-count-other": "francos CFA de África Occidental",
            "symbol": "XOF"
          },
          "XPD": {
            "displayName": "paladio",
            "displayName-count-one": "paladio",
            "displayName-count-other": "paladio",
            "symbol": "XPD"
          },
          "XPF": {
            "displayName": "franco CFP",
            "displayName-count-one": "franco CFP",
            "displayName-count-other": "francos CFP",
            "symbol": "CFPF"
          },
          "XPT": {
            "displayName": "platino",
            "displayName-count-one": "platino",
            "displayName-count-other": "platino",
            "symbol": "XPT"
          },
          "XRE": {
            "displayName": "fondos RINET",
            "symbol": "XRE"
          },
          "XSU": {
            "displayName": "XSU",
            "symbol": "XSU"
          },
          "XTS": {
            "displayName": "código reservado para pruebas",
            "symbol": "XTS"
          },
          "XUA": {
            "displayName": "XUA",
            "symbol": "XUA"
          },
          "XXX": {
            "displayName": "moneda desconocida",
            "displayName-count-one": "(moneda desconocida)",
            "displayName-count-other": "(moneda desconocida)",
            "symbol": "¤"
          },
          "YDD": {
            "displayName": "dinar yemení",
            "symbol": "YDD"
          },
          "YER": {
            "displayName": "rial yemení",
            "displayName-count-one": "rial yemení",
            "displayName-count-other": "riales yemeníes",
            "symbol": "YER"
          },
          "YUD": {
            "displayName": "dinar fuerte yugoslavo",
            "symbol": "YUD"
          },
          "YUM": {
            "displayName": "super dinar yugoslavo",
            "symbol": "YUM"
          },
          "YUN": {
            "displayName": "dinar convertible yugoslavo",
            "displayName-count-one": "dinar convertible yugoslavo",
            "displayName-count-other": "dinares convertibles yugoslavos",
            "symbol": "YUN"
          },
          "YUR": {
            "displayName": "YUR",
            "symbol": "YUR"
          },
          "ZAL": {
            "displayName": "rand sudafricano (financiero)",
            "symbol": "ZAL"
          },
          "ZAR": {
            "displayName": "rand",
            "displayName-count-one": "rand",
            "displayName-count-other": "rands",
            "symbol": "ZAR",
            "symbol-alt-narrow": "R"
          },
          "ZMK": {
            "displayName": "kwacha zambiano (1968–2012)",
            "displayName-count-one": "kwacha zambiano (1968–2012)",
            "displayName-count-other": "kwachas zambianos (1968–2012)",
            "symbol": "ZMK"
          },
          "ZMW": {
            "displayName": "kuacha zambiano",
            "displayName-count-one": "kuacha zambiano",
            "displayName-count-other": "kuachas zambianos",
            "symbol": "ZMW",
            "symbol-alt-narrow": "ZK"
          },
          "ZRN": {
            "displayName": "nuevo zaire zaireño",
            "symbol": "ZRN"
          },
          "ZRZ": {
            "displayName": "zaire zaireño",
            "symbol": "ZRZ"
          },
          "ZWD": {
            "displayName": "dólar de Zimbabue",
            "symbol": "ZWD"
          },
          "ZWL": {
            "displayName": "dólar zimbabuense",
            "symbol": "ZWL"
          },
          "ZWR": {
            "displayName": "ZWR",
            "symbol": "ZWR"
          }
        }
      }
    }
  }
}
