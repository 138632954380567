import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../shared-services/notification/notification.service';
import { DEFAULT_MSG_KEY, defaultErrorMessages } from './default-error-message-token';
import { Router } from '@angular/router';

@Injectable()
export class NotifyHttpInterceptorInterceptor implements HttpInterceptor {
  constructor(
    private notifiService: NotificationService,
    private router: Router,
    ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((err: any) => {
        try {
          let errorMessage = '';
          if (err.error && err.error.errors) {
            err.error.errors.forEach(error => {
              errorMessage += `${error.message} (${error.code})\n`;
            });
          } else {
            errorMessage += this.handleDefaultCase(err, request);
          }
          const route = this.getRoute(errorMessage, err);
          if (route) {
            this.router.navigate([route]);
          }
          this.notifiService.showErrorNotification(errorMessage.charAt(0).toUpperCase() + errorMessage.substr(1));
        } catch (e) {
          this.notifiService.showErrorNotification('An error occurred + ' + e);
        }
        return throwError(err);
      }));
  }

  handleDefaultCase(err: any, request: HttpRequest<unknown>): string {
    const defaultMsgKey = request.context?.get(DEFAULT_MSG_KEY);
    if (defaultMsgKey && defaultErrorMessages[defaultMsgKey]) {
      return defaultErrorMessages[defaultMsgKey];
    }
    return err.message;
  }

  getRoute(errorMessage: string, err: any): string {
    if (errorMessage.toLowerCase().includes('invalid or expired jwt')) {
      return '/auth-error/EXPJWT9999';
    } else if (err.headers.get('error-reason') === 'CallerIpNotAllowed') {
      return '/auth-error/IPNOTALLOWED';
    } else if (err.headers.get('error-reason') === 'CallerIpBlocked') {
      return '/auth-error/IPBLOCKED';
    }
    return null;
  }

}
